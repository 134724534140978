import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Footer from 'components/footer/Footer';
import Header from 'components/header/Header';
import Sticky from 'components/sticky/Sticky';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { Fragment, useCallback, useState } from 'react';


const AppLayout = ({ children, navbar, title = 'deCheckers', height = 800 }) => {
	const [isFixed, setIsFixed] = useState(false);
	const theme = useTheme();
	const router = useRouter();

	const toggleIsFixed = useCallback(fixed => {
		setIsFixed(fixed);
	}, []);

	let showArt = true;

	if (router.asPath.startsWith('/factchecks')) {
		showArt = false;
	}

	if(router.asPath.startsWith('/blog')) {
		showArt = false;
	}

	
	return <Fragment>
		<Head>
			<title>{title}</title>
			<meta charSet="utf-8" />
			<meta name="viewport" content="initial-scale=1.0, width=device-width" />
			<meta name="description" key='description' content="Non-profitorganisatie die het publieke debat wil voeden met feiten. deCheckers werkt samen met journalisten van Knack, VRT NWS en Factcheck.Vlaanderen." />
		</Head>

		<Sticky fixedOn={0} onSticky={toggleIsFixed}>
			<Header isFixed={isFixed} />
		</Sticky>
		{
			showArt && <div style={{ backgroundColor: '#f0f2ff', position: 'absolute', width: '100vw', height: height, zIndex: -1, clipPath: 'polygon(0% 32%, 7% 42%, 52% 0%, 100% 0%, 100% 91%, 0% 95%, 0% 100%)' }}></div>
		}


		{/* polygon(0 35%, 9% 42%, 37% 0, 100% 0%, 100% 59%, 0 79%) */}

		{navbar && <div className="section-after-sticky">{navbar}</div>}
		{/* {!navbar ? <div className="section-after-sticky" style={{backgroundImage: "url('/assets/images/bg.svg')", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPositionY: '-80vh', backgroundAttachment: 'scroll'}}>{children}</div> : children} */}
		{!navbar ? <div className="section-after-sticky">{children}</div> : children}
		<Footer />
	</Fragment>;
};

export default AppLayout;